


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// debug outlining
[showborders2] {
	.vueperSlides {
		background-color: fade(blue, 20);
		
		&__arrows {
			outline: 1px solid fade(blue, 20);			
			background-color: fade(blue, 20);			
		}
		&__arrow {
			//outline: 1px solid fade(blue, 30);			
			//background-color: fade(blue, 30);
		}
		&__bullets {
			background-color: fade(green, 20);
		}
	}
		.vueperSlide {
		background-color: fade(yellow, 20);
		border: 1px solid fade(orange, 30);
	}
}

// required styles
.vueperSlides {
	position: relative;
	overflow: hidden;		

	// disable mouse drag and touch if there's only one slide
	&--atStart&--atEnd {
		.vueperSlides__track {
			pointer-events: none;
		}
	}

	&--fixed-height {
		.vueperSlides__inner,
		.vueperSlides__parallax-wrapper,
		.vueperSlide {
			height: inherit;
		}

		.vueperSlides__parallax-wrapper {
			padding-bottom: 0 !important;
		}
	}
	&__inner {
		position: relative;
		user-select: none;
	}
	&__parallax-wrapper {
		position: relative;
		overflow: hidden;

		.vueperSlides--3d & {
			overflow: visible;
		}
	}

	&__track {
		position: absolute;
		top: 0;
		height: 100%;
		left: 0;
		right: 0;
		overflow: hidden;
		z-index: 1;

		.vueperSlides--parallax & {
			height: 200%;
			transform: translateY(0);
		}

		.vueperSlides--touchable & {
			cursor: ew-resize;
			cursor: -webkit-grab;
			cursor: grab;

			&--mousedown, &--dragging {
				cursor: -webkit-grabbing;
				cursor: grabbing;
			}
		}

		.vueperSlides--3d & {
			overflow: visible;
			perspective: 100em;
		}
	}
	&__track-inner {
		white-space: nowrap;
		transition: 0.5s ease-in-out transform;
		height: 100%;

		.vueperSlides--fade & {
			white-space: normal;
			transition: none;
		}

		.vueperSlides--3d & {
			transform-style: preserve-3d;
		}

		.vueperSlides__track--mousedown & {
			transition: 0.2s ease-in-out transform !important;
		}

		.vueperSlides__track--dragging & {
			transition: none;
		}

		.vueperSlides__track--no-animation & {
			transition-duration: 0s;
		}
	}

	&__arrows {
		z-index: 1;
	}
	&__arrow {
		color: inherit;
		fill: currentColor;
		min-width: 1em;
		text-align: center;
		cursor: pointer;
		user-select: none;
		outline: none;
		z-index: 2;
	}

	&__paused {
		position: absolute;
		transition: 0.3s ease-in-out;
	}

	&__bullets {
		display: flex;
		justify-content: center;

		&--outside {
			position: relative;
		}
	}
	&__bullet {
		color: inherit;
		cursor: pointer;
		user-select: none;
		outline: none;
		z-index: 2;
		
		display: flex;
		justify-content: center;
		align-items: center;

		&::-moz-focus-inner {
			border: 0;
		}
	}
}
.vueperSlide {
	white-space: normal;
	background-size: cover;
	display: inline-block;
	position: relative;
	width: 100%;
	height: 100%;

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
	}

	&__content-wrapper:not(&__content-wrapper--outside-top):not(&__content-wrapper--outside-bottom) {
		//position: absolute;
	}

	.vueperSlides--fade & {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: .8s ease-in-out opacity;
		transition-duration: inherit;

		&--active, &--visible {
			z-index: 1;
			opacity: 1;
		}
	}

	.vueperSlides--3d & {
		position: absolute;
		// Trickier than rotateY(180deg) translateZ($slideshowWidth / 2);
		// But doesn't require to set a fixed width on the slideshow ;)
		// transform: rotateY(270deg) translateX(-50%) rotateY(-90deg);
		z-index: -1;

		&--previous-slide, &--active, &--next-slide {z-index: 0;}
		&--active {z-index: 1;}
		&[face=front] {
			// Trickier than rotateY(0deg) translateZ($slideshowWidth / 2),
			// But doesn't require to set a fixed width on the slideshow ;)
			transform: rotateY(90deg) translateX(-50%) rotateY(-90deg);
		}

		&[face=right] {
			transform: rotateY(90deg) translateX(50%);
			transform-origin: 100% 0;
		}

		&[face=back] {
			// Trickier than rotateY(180deg) translateZ($slideshowWidth / 2);
			// But doesn't require to set a fixed width on the slideshow ;)
			transform: rotateY(270deg) translateX(-50%) rotateY(-90deg);
		}

		&[face=left] {
			transform: rotateY(270deg) translateX(-50%);
			transform-origin: 0 0;
		}
	}
}
