/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbHomeFeatures__inner {
  display: grid;
  padding-top: 0.65em;
  padding-bottom: 1em;
}
.AbHomeFeatures__inner > * {
  min-height: 0;
  min-width: 0;
}
.AbHomeFeatures__inner > *:nth-child(1) {
  grid-area: a;
}
.AbHomeFeatures__inner > *:nth-child(2) {
  grid-area: b;
}
.AbHomeFeatures__inner > *:nth-child(3) {
  grid-area: c;
}
.AbHomeFeatures__inner > *:nth-child(4) {
  grid-area: d;
}
.AbHomeFeatures__inner > *:nth-child(5) {
  grid-area: e;
}
.AbHomeFeatures__inner > *:nth-child(6) {
  grid-area: f;
}
.AbHomeFeatures__inner > *:nth-child(7) {
  grid-area: g;
}
.AbHomeFeatures__inner > *:nth-child(8) {
  grid-area: h;
}
.AbHomeFeatures__inner > *:nth-child(9) {
  grid-area: i;
}
.AbHomeFeatures__inner > *:nth-child(10) {
  grid-area: j;
}
@media (min-width: 1023px ) {
  .AbHomeFeatures__inner {
    grid-column-gap: 24px;
  }
}
@media (max-width: 1023px ) {
  .AbHomeFeatures__inner {
    grid-column-gap: 20px;
  }
}
@media (max-width: 750px ) {
  .AbHomeFeatures__inner {
    grid-column-gap: 10px;
  }
}
@media (min-width: 1023px ) {
  .AbHomeFeatures__inner {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-areas: "a a a a b b b b c c c c";
  }
}
@media (max-width: 1023px ) {
  .AbHomeFeatures__inner {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: ". . a a a a . ." "b b b b c c c c";
  }
}
@media (max-width: 750px ) {
  .AbHomeFeatures__inner {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "a" "b" "c";
  }
}
.AbHomeFeature {
  padding-bottom: 1em;
}
[showBorders3] .AbHomeFeature {
  outline: 1px solid green;
}
.AbHomeFeatures__item--eventPost {
  color: inherit;
  text-decoration: inherit;
}
.AbHomeFeature__overTitle {
  color: #DD2C6C;
  padding-bottom: 0.5em;
}
.AbHomeFeature__title {
  color: #0B2CC2;
  padding-bottom: 0.85em;
}
.AbHomeFeature__eventCat {
  margin-bottom: 0.3em;
}
.AbHomeFeature__eventDateTime {
  margin-top: -0.85em;
  margin-bottom: 1em;
}
.AbHomeFeature__image {
  margin-bottom: 1em;
}
@media (min-width: 1023px ) {
  .AbHomeFeature__image {
    padding-bottom: 53%;
  }
}
@media (max-width: 1023px ) {
  .AbHomeFeature__image {
    padding-bottom: 53%;
  }
}
@media (max-width: 750px ) {
  .AbHomeFeature__image {
    padding-bottom: 53%;
  }
}
