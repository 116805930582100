/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbMainFooter {
  overflow: hidden;
  background-color: rgba(196, 120, 6, 0.1);
}
@media (min-width: 1023px ) {
  .AbMainFooter {
    margin-top: 3em;
    padding-top: 55px;
  }
}
@media (max-width: 1023px ) {
  .AbMainFooter {
    margin-top: 3em;
    padding-top: 40px;
  }
}
@media (max-width: 750px ) {
  .AbMainFooter {
    margin-top: 3em;
  }
}
@media (min-width: 1023px ) {
  .AbMainFooter__pinkLinks {
    padding-bottom: 50px;
  }
}
@media (max-width: 1023px ) {
  .AbMainFooter__pinkLinks {
    padding-bottom: 40px;
  }
}
@media (max-width: 750px ) {
  .AbMainFooter__pinkLinks {
    display: none;
  }
}
.AbMainFooter__pinkLinksLink {
  color: #DD2C6C;
  text-decoration: none;
  margin-right: 1.2em;
}
.AbMainFooter__sitemap {
  display: block;
  color: #C47806;
}
@media (min-width: 1023px ) {
  .AbMainFooter__sitemap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 1023px ) {
  .AbMainFooter__sitemap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 750px ) {
  .AbMainFooter__sitemap {
    display: none;
  }
}
@media (min-width: 1023px ) {
}
@media (max-width: 1023px ) {
}
.AbMainFooter__sitemapGroup {
  padding: 0 1em;
}
.AbMainFooter__sitemapGroup:first-child {
  margin-left: -1em;
}
.AbMainFooter__sitemapGroup:last-child {
  margin-right: -1em;
}
.AbMainFooter__sitemapList {
  list-style: none;
  margin: 0;
}
.AbMainFooter__sitemapLink {
  display: block;
  text-decoration: none;
  color: inherit;
}
.AbMainFooter__logo {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1023px ) {
  .AbMainFooter__logo {
    height: 200px;
  }
}
@media (max-width: 1023px ) {
  .AbMainFooter__logo {
    height: 200px;
  }
}
.AbMainFooter__logoImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -30px;
  background-image: url(../assets/images/logo--gold.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}
