.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.MhHeadInfos__debug {
  position: fixed;
  right: 1em;
  bottom: 2em;
  max-width: 500px;
  max-height: calc(100vh - 4em);
  z-index: 10;
  overflow: auto;
}
.MhHeadInfos__debug {
  border: 1px solid;
  padding: 0.5em;
  background-color: #ffffb3;
  font-size: 12px;
  line-height: 1.2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.MhHeadInfos__debug p + p {
  border-top: 1px dotted;
}
