@swatches : {
	grey        : #2B2E34;
	blue        : #0B2CC2;
	lighterBlue : #1478C2;
	gold        : #C47806;
	lightGold   : #C4950B;
	pink        : #DD2C6C;
	white       : #ffffff;
}
@screenWidths : {
	xs : 350px;
	sm : 750px;
	md : 1023px;
	lg : 1500px;
	xl : 1800px;
}
@mq : {
	xs : ~"(max-width:" @screenWidths[xs] ~")";
	sm : ~"(max-width:" @screenWidths[sm] ~")";
	md : ~"(max-width:" @screenWidths[md] ~")";
	dt : ~"(min-width:" @screenWidths[md] ~")";
	lg : ~"(min-width:" @screenWidths[lg] ~")";
	xl : ~"(min-width:" @screenWidths[xl] ~")";
}
@transitions : {
	colorChangeDuration : 0.1s;
	splashscreenDisappearDuration : 0.5s;
}
@assetsPath: "@/../../assets";

/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
