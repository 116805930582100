/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbMainHeader {
  position: relative;
}
@media (max-width: 750px ) {
  .AbMainHeader {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 5;
  }
}
.AbMainHeader__wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0B2CC2;
}
@media (min-width: 1023px ) {
  .AbMainHeader__wrapper {
    height: 72px;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__wrapper {
    height: 72px;
  }
}
@media (max-width: 750px ) {
  .AbMainHeader__wrapper {
    height: 50px;
  }
}
.AbMainHeader__brand {
  position: relative;
  color: transparent;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
}
@media (min-width: 1023px ) {
  .AbMainHeader__brand {
    padding-bottom: 0.25em;
    width: 145px;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__brand {
    padding-bottom: 0.5em;
    width: 145px;
  }
}
@media (max-width: 750px ) {
  .AbMainHeader__brand {
    padding-bottom: 0.1em;
    width: 120px;
  }
}
.AbMainHeader__brandLink {
  display: block;
  flex-grow: 1;
  background-image: url(../assets/images/brand--blue.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}
.AbMainHeader__brand span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.AbMainHeader__linkGroups {
  display: flex;
  flex-shrink: 0;
}
@media (min-width: 1023px ) {
  .AbMainHeader__linkGroups {
    display: flex;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__linkGroups {
    display: none;
  }
}
.AbMainHeader__linkGroup {
  position: relative;
  display: flex;
  flex-shrink: 0;
  margin-left: 1.1em;
}
.AbMainHeader__linkGroupTitle {
  transition: all 0.1s ease;
  color: #C47806;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  padding-top: 0.5em;
}
.AbMainHeader__linkGroupTitle:hover {
  color: #0B2CC2;
}
.AbMainHeader__linkGroupList {
  position: absolute;
  top: 100%;
  left: 50%;
  margin: 0;
  list-style: none;
  max-width: 350px;
  transform: translateX(-50%);
  padding: 1em 1em;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  background-color: #0B2CC2;
  transition: all 0.2s ease;
}
.AbMainHeader__linkGroupList::before {
  position: absolute;
  top: 0;
  left: 50%;
  height: 25px;
  width: 25px;
  transform: translateX(-50%) translateY(-10px) rotate(45deg);
  background-color: #0B2CC2;
  content: "";
}
.AbMainHeader__linkGroupListItem {
  text-align: center;
}
.AbMainHeader__linkGroupListItem a {
  transition: all 0.1s ease;
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  color: white;
  text-decoration: none;
  line-height: 2.5em;
  white-space: nowrap;
}
.AbMainHeader__linkGroupListItem:hover a {
  color: #C47806;
}
.AbMainHeader__linkGroup:focus,
.AbMainHeader__linkGroup:focus-within {
  outline: none;
  border: none;
  z-index: 20;
}
.AbMainHeader__linkGroup:focus .AbMainHeader__linkGroupTitle,
.AbMainHeader__linkGroup:focus-within .AbMainHeader__linkGroupTitle {
  color: #0B2CC2;
}
.AbMainHeader__linkGroup:focus .AbMainHeader__linkGroupList,
.AbMainHeader__linkGroup:focus-within .AbMainHeader__linkGroupList {
  pointer-events: auto;
  opacity: 1;
}
.AbMainHeader__menuBtn {
  position: relative;
  display: flex;
  width: 40px;
  padding-left: 15px;
  cursor: pointer;
}
[showBorders4] .AbMainHeader__menuBtn {
  outline: 1px solid red;
}
@media (min-width: 1023px ) {
  .AbMainHeader__menuBtn {
    display: none;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__menuBtn {
    padding-top: 5px;
    display: flex;
  }
}
@media (max-width: 750px ) {
  .AbMainHeader__menuBtn {
    padding-top: 0px;
    display: flex;
  }
}
.AbMainHeader__menuBtnIcon {
  display: block;
  flex-grow: 1;
  background-image: url(../assets/images/icon--hamburger.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.AbMainHeader__searchBtn {
  position: relative;
  display: flex;
  padding-left: 15px;
  padding-right: 14px;
  cursor: pointer;
}
[showBorders4] .AbMainHeader__searchBtn {
  outline: 1px solid red;
}
@media (min-width: 1023px ) {
  .AbMainHeader__searchBtn {
    width: 40px;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 0px;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__searchBtn {
    width: 54px;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 14px;
  }
}
@media (max-width: 750px ) {
  .AbMainHeader__searchBtn {
    padding-top: 0px;
    width: 35px;
    padding-left: 15px;
    padding-right: 0px;
  }
}
.AbMainHeader__searchBtnIcon {
  display: block;
  flex-grow: 1;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 1023px ) {
  .AbMainHeader__searchBtnIcon {
    background-image: url(../assets/images/icon--lense-lightBlue.svg);
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__searchBtnIcon {
    background-image: url(../assets/images/icon--lense-blue.svg);
  }
}
.AbMainHeader__searchBtn:focus {
  outline: none;
  border: none;
}
.AbMainHeader__searchForm {
  position: absolute;
  top: 100%;
  left: 0%;
  right: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: 0;
}
.AbMainHeader__searchFormInner {
  position: relative;
  background-color: #1478C2;
}
@media (min-width: 1023px ) {
  .AbMainHeader__searchFormInner {
    padding: 0.65em;
    padding-left: 3em;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__searchFormInner {
    padding: 0.8em;
    padding-left: 3em;
  }
}
@media (max-width: 750px ) {
  .AbMainHeader__searchFormInner {
    padding: 0.5em;
    padding-left: 1.85em;
  }
}
.AbMainHeader__searchFormIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(../assets/images/icon--lense-white.svg?1);
}
@media (min-width: 1023px ) {
  .AbMainHeader__searchFormIcon {
    left: 1em;
    width: 0.85em;
    height: 0.85em;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__searchFormIcon {
    left: 1em;
    width: 0.85em;
    height: 0.85em;
  }
}
@media (max-width: 750px ) {
  .AbMainHeader__searchFormIcon {
    left: 0.5em;
    width: 0.85em;
    height: 0.85em;
  }
}
.AbMainHeader__searchFormInput {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.AbMainHeader__searchForm::before {
  position: absolute;
  top: 0;
  height: 18px;
  width: 18px;
  transform: translateX(-35px) translateY(-50%) rotate(45deg);
  background-color: #1478C2;
  content: "";
}
@media (min-width: 1023px ) {
  .AbMainHeader__searchForm::before {
    right: 0em;
    height: 18px;
    width: 18px;
  }
}
@media (max-width: 1023px ) {
  .AbMainHeader__searchForm::before {
    right: 2em;
    height: 18px;
    width: 18px;
  }
}
@media (max-width: 750px ) {
  .AbMainHeader__searchForm::before {
    right: 4.5%;
    height: 14px;
    width: 14px;
  }
}
.AbMainHeader__searchBtn:focus + .AbMainHeader__searchForm,
.AbMainHeader__searchForm--isFocused,
.AbMainHeader__searchForm:focus,
.AbMainHeader__searchForm:focus-within {
  opacity: 1;
  pointer-events: auto;
}
.AbMobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 10;
  transition: all 0.35s ease;
  transform: translateY(-120%);
  overflow: hidden;
}
.AbMobileMenu--isActive {
  transform: translateY(0%);
}
@media (min-width: 1023px ) {
  .AbMobileMenu {
    display: none;
  }
}
@media (max-width: 1023px ) {
  .AbMobileMenu {
    display: flex;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu {
    display: flex;
  }
}
.AbMobileMenu__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
[showBorders3] .AbMobileMenu__inner > * {
  outline: 1px solid rgba(255, 0, 0, 0.75);
}
.AbMobileMenu__header {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1023px ) {
  .AbMobileMenu__header {
    height: 72px;
  }
}
@media (max-width: 1023px ) {
  .AbMobileMenu__header {
    height: 72px;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__header {
    height: 50px;
  }
}
.AbMobileMenu__body {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px ) {
  .AbMobileMenu__body {
    padding-top: 3em;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__body {
    padding-top: 2em;
  }
}
.AbMobileMenu__footer {
  position: relative;
  height: 200px;
  display: flex;
}
.AbMobileMenu__brand {
  position: relative;
  color: transparent;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
}
[showBorders3] .AbMobileMenu__brand {
  outline: 1px solid rgba(255, 255, 0, 0.35);
}
@media (min-width: 1023px ) {
  .AbMobileMenu__brand {
    padding-bottom: 0.25em;
    width: 145px;
  }
}
@media (max-width: 1023px ) {
  .AbMobileMenu__brand {
    padding-bottom: 0.5em;
    width: 145px;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__brand {
    padding-bottom: 0.1em;
    width: 120px;
  }
}
.AbMobileMenu__brandLink {
  display: block;
  flex-grow: 1;
  background-image: url(../assets/images/brand--white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}
.AbMobileMenu__brand span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.AbMobileMenu__closeBtn {
  position: relative;
  display: flex;
  user-select: none;
  color: transparent;
  width: 50px;
  overflow: hidden;
}
[showBorders3] .AbMobileMenu__closeBtn {
  outline: 1px solid rgba(255, 255, 0, 0.35);
}
@media (max-width: 1023px ) {
  .AbMobileMenu__closeBtn {
    padding: 22px 0;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__closeBtn {
    padding: 12px 0;
  }
}
.AbMobileMenu__closeBtnIcon {
  display: block;
  flex-grow: 1;
  background-image: url(../assets/images/icon--cross.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
}
.AbMobileMenu__linkGroups {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-auto-rows: min-content;
}
.AbMobileMenu__linkGroups > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .AbMobileMenu__linkGroups {
    grid-column-gap: 24px;
  }
}
@media (max-width: 1023px ) {
  .AbMobileMenu__linkGroups {
    grid-column-gap: 20px;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__linkGroups {
    grid-column-gap: 10px;
  }
}
@media (max-width: 1023px ) {
  .AbMobileMenu__linkGroups {
    grid-template-columns: repeat(3, 1fr);
    font-size: 16px;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__linkGroups {
    grid-template-columns: repeat(1, 1fr);
    font-size: 18px;
  }
}
[showBorders3] .AbMobileMenu__linkGroup {
  outline: 1px solid rgba(0, 255, 255, 0.35);
}
@media (max-width: 1023px ) {
  .AbMobileMenu__linkGroup {
    padding-bottom: 3em;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__linkGroup {
    padding-bottom: 0em;
  }
}
@media (max-width: 1023px ) {
  .AbMobileMenu__linkGroupTitle {
    padding-bottom: 0.25em;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__linkGroupTitle {
    text-align: center;
    padding: 0.5em 0;
    cursor: pointer;
  }
}
.AbMobileMenu__linkGroupToggleIndicator {
  display: inline-flex;
  width: 0.5em;
  height: 0.65em;
  margin-left: 0.35em;
  background-image: url(../assets/images/icon--triangleDown.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.AbMobileMenu__linkGroupToggleIndicator--isActive {
  transform: rotate(180deg);
}
.AbMobileMenu__linkGroupList {
  list-style: none;
  margin: 0;
  position: relative;
}
@media (max-width: 750px ) {
  .AbMobileMenu__linkGroupList {
    margin: 0.5em 0;
    padding: 0.5em 0;
    text-align: center;
    background-color: #1478C2;
  }
}
.AbMobileMenu__linkGroupListItem {
  padding-left: 0;
}
.AbMobileMenu__linkGroupLink {
  display: block;
  color: inherit;
  text-decoration: none;
}
@media (max-width: 1023px ) {
  .AbMobileMenu__linkGroupLink {
    padding: 0.25em 0;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__linkGroupLink {
    padding: 0.5em 0;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__expander {
    display: none;
  }
}
[showBorders3] .AbMobileMenu__footerLinks {
  outline: 1px solid rgba(255, 192, 203, 0.85);
}
@media (min-width: 1023px ) {
  .AbMobileMenu__footerLinks {
    padding: 3em 0;
  }
}
@media (max-width: 1023px ) {
  .AbMobileMenu__footerLinks {
    padding: 3em 0;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__footerLinks {
    padding: 2.75em 0;
    text-align: center;
  }
}
.AbMobileMenu__footerLinks a {
  color: inherit;
  text-decoration: none;
}
@media (max-width: 1023px ) {
  .AbMobileMenu__footerLinks a {
    padding: 0.75em;
    display: inline-block;
  }
}
@media (max-width: 750px ) {
  .AbMobileMenu__footerLinks a {
    padding: 0.5em;
    display: block;
  }
}
.AbMobileMenu__footerLinks a:first-child {
  padding-left: 0;
}
.AbMobileMenu__mdLogo {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}
.AbMobileMenu__mdLogoImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background-image: url(../assets/images/logo--white.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}
.AbMobileMenu__smLogo {
  position: relative;
  overflow: hidden;
  display: flex;
  height: 170px;
  flex-shrink: 0;
}
.AbMobileMenu__smLogoImage {
  display: block;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  background-image: url(../assets/images/logo--short-white.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}
