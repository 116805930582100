/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.AbHomeGallery {
  position: relative;
}
.AbHomeGallery .vueperSlides__inner {
  pointer-events: none;
}
@media (min-width: 1023px ) {
  .AbHomeGallery .vueperSlides__parallax-wrapper {
    padding-bottom: 32% !important;
  }
}
@media (max-width: 1023px ) {
  .AbHomeGallery .vueperSlides__parallax-wrapper {
    padding-bottom: 52% !important;
  }
}
@media (max-width: 750px ) {
  .AbHomeGallery .vueperSlides__parallax-wrapper {
    padding-bottom: 90% !important;
  }
}
.AbHomeGallery .vueperSlide {
  position: relative;
}
.AbHomeGallery .vueperSlides--fade .vueperSlide--clone {
  display: none;
}
.AbHomeGallery .vueperSlides--fade .vueperSlide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.AbHomeGallery .vueperSlides__bullets {
  position: relative;
  padding: 0.5em 0;
  z-index: 3;
  background-color: white;
}
.AbHomeGallery .vueperSlides__bullet {
  height: 8px;
  width: 70px;
  margin: 0 0.25em;
  transition: all 0.2s ease;
  overflow: hidden;
  background-color: #BFCCCC;
  color: transparent;
}
.AbHomeGallery .vueperSlides__bullet--active {
  background-color: #0B2CC2;
}
@media (max-width: 750px ) {
  .AbHomeGallery .vueperSlides__bullets {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  .AbHomeGallery .vueperSlides__bullet {
    margin: 0 1.5vw;
  }
}
.AbHomeGallery__slider {
  position: relative;
}
.AbHomeGallery__sliderImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.AbHomeGallery__colorOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  background-color: rgba(43, 46, 52, 0.15);
}
@media (min-width: 1023px ) {
  .AbHomeGallery__colorOverlay {
    display: none;
  }
}
@media (max-width: 1023px ) {
  .AbHomeGallery__colorOverlay {
    display: none;
  }
}
@media (max-width: 750px ) {
  .AbHomeGallery__colorOverlay {
    display: block;
  }
}
.AbHomeGallery__logo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.5em;
  height: 50%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
  z-index: 2;
}
@media (min-width: 1023px ) {
  .AbHomeGallery__logo {
    height: 75%;
  }
}
@media (max-width: 1023px ) {
  .AbHomeGallery__logo {
    height: 75%;
  }
}
@media (max-width: 750px ) {
  .AbHomeGallery__logo {
    height: 50%;
  }
}
.AbHomeGallery__logoImage {
  position: relative;
  flex-grow: 1;
  height: 100%;
  background-image: url(../assets/images/logo--short-white.svg);
  background-repeat: no-repeat;
  background-position: right 24px bottom;
  background-size: contain;
}
