/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.MhCookieNotice {
  position: fixed;
  z-index: 100;
  transition: all 0.2s ease;
  transform: translateY(0%);
  background: #2B2E34;
  color: white;
}
@media (min-width: 1023px ) {
  .MhCookieNotice {
    right: 18px;
    bottom: 18px;
    width: 450px;
  }
}
@media (max-width: 1023px ) {
  .MhCookieNotice {
    right: 18px;
    bottom: 18px;
    width: 450px;
  }
}
@media (max-width: 750px ) {
  .MhCookieNotice {
    right: 3vw;
    left: 3vw;
    bottom: 3vw;
    width: auto;
  }
}
.MhCookieNotice--isHidden {
  transform: translateY(120%);
  pointer-events: none;
}
.MhCookieNotice__inner {
  display: flex;
  justify-content: space-between;
  padding: 0.75em;
}
.MhCookieNotice__textWrapper {
  padding-right: 1em;
}
.MhCookieNotice__textWrapper a {
  transition: all 0.1s ease;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  white-space: nowrap;
}
.MhCookieNotice__textWrapper a:hover {
  border-bottom-color: currentColor;
}
.MhCookieNotice__btn {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;
  text-align: inherit;
  cursor: pointer;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  color: transparent;
  transition: all 0.1s ease;
  opacity: 0.75;
  background-image: url(../../assets/images/icon--cross.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}
.MhCookieNotice__btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.MhCookieNotice__btn:hover {
  opacity: 1;
}
