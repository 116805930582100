// @ is an alias to /src

@fontsPath: "@/../../assets/fonts/";   

/* marcellus-regular - latin */
@font-face {
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  src: url('@{fontsPath}/marcellus-v8-latin/marcellus-v8-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/marcellus-v8-latin/marcellus-v8-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* marcellus-sc-regular - latin */
@font-face {
  font-family: 'Marcellus SC';
  font-style: normal;
  font-weight: 400;
  src: url('@{fontsPath}/marcellus-sc-v8-latin/marcellus-sc-v8-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/marcellus-sc-v8-latin/marcellus-sc-v8-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* quattrocento-regular - latin */
@font-face {
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 400;
  src: url('@{fontsPath}/quattrocento-v12-latin/quattrocento-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/quattrocento-v12-latin/quattrocento-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-700 - latin */
@font-face {
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  src: url('@{fontsPath}/quattrocento-v12-latin/quattrocento-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/quattrocento-v12-latin/quattrocento-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-sans-regular - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: normal;
  font-weight: 400;
  src: url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-sans-italic - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: italic;
  font-weight: 400;
  src: url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-sans-700 - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: normal;
  font-weight: 700;
  src: url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-sans-700italic - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: italic;
  font-weight: 700;
  src: url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('@{fontsPath}/quattrocento-sans-v13-latin/quattrocento-sans-v13-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
