/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}.MhDebugGrid {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  visibility: hidden;
  z-index: 100;
}
[showBorders1] .MhDebugGrid {
  visibility: visible;
}
.MhDebugGrid > * {
  flex-grow: 1;
  height: 100vh;
}
.MhDebugGrid__columns {
  display: grid;
  max-width: 1140px;
  grid-template-rows: 1fr 0px;
}
.MhDebugGrid__columns > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .MhDebugGrid__columns {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 1023px ) {
  .MhDebugGrid__columns {
    padding-left: 3vw;
    padding-right: 3vw;
  }
}
@media (max-width: 750px ) {
  .MhDebugGrid__columns {
    padding-left: 3vw;
    padding-right: 3vw;
  }
}
@media (min-width: 1023px ) {
  .MhDebugGrid__columns {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 24px;
  }
}
@media (max-width: 1023px ) {
  .MhDebugGrid__columns {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 20px;
  }
}
@media (max-width: 750px ) {
  .MhDebugGrid__columns {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
  }
}
.MhDebugGrid__colum {
  background-color: rgba(0, 128, 0, 0.2);
}
